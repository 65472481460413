<template>
  <div class="about">
    <div class="swiper-container mySwiper " >
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img :class="{'show': selectIndex == 1}" src="@/static/images/about/02_banner1.jpg">
        </div>
        <div class="swiper-slide">
          <img :class="{'show': selectIndex == 2}" src="@/static/images/about/02_banner2.jpg">
        </div>
        <div class="swiper-slide">
          <img :class="{'show': selectIndex == 3}" src="@/static/images/about/02_banner3.jpg">
        </div>
        <div class="swiper-slide">
          <img :class="{'show': selectIndex == 4}" src="@/static/images/about/02_banner4.jpg">
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>

    <div class="bg">
      <div>
        <div class="left-layer">
          <img src="../static/images/about/title.png">
          <p class="title">关于我们</p>

          <div class="menu-main">
            <ul class="menu">
              <li class="animate__animated animate__slideInLeft">
                <a  :class="{'active' : selectIndex == 1}" @click="selectMenu(1)" href="javascipt:void(0)">关于帝森共富宝</a>
              </li>
              <li class="animate__animated animate__slideInLeft">
                <a :class="{'active' : selectIndex == 2}" @click="selectMenu(2)" href="javascipt:void(0)">帝森克罗德新能源</a>
              </li>
              <li class="animate__animated animate__slideInLeft">
                <a :class="{'active' : selectIndex == 3}" @click="selectMenu(3)" href="javascipt:void(0)">济南历城控股集团</a>
              </li>
              <li class="animate__animated animate__slideInLeft">
                <a :class="{'active' : selectIndex == 4}" @click="selectMenu(4)" href="javascipt:void(0)">帝森克罗德集团</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="describe" :style="'bottom:' + bottom">

          <div  v-show="selectIndex == 1" class="describe-main animate__animated animate__fadeIn" style="padding-right: 220px">
            <p class="title">帝森共富宝</p>
            <p class="title-sub">“共”&nbsp;&nbsp;收益，&nbsp;&nbsp;“富”&nbsp;生活</p>
            <p class="content">
              <span style="font-size: 14px;letter-spacing: 1px">帝森共富宝是帝森克罗德新能源公司旗下的一款户用光伏电站品牌。</span><br/><br/>
              帝森共富宝专注于户用分布式光伏发电项目。公司以“辅农”&nbsp;&nbsp;“扶农”&nbsp;&nbsp;“富农”为经营的核心价值，以“通过绿色能源改变生活”为愿景，为实现<span style="font-size: 20px;font-weight: 400">共</span>同<span style="font-size: 20px;font-weight: 400">富</span>裕而奋斗。 <br/>帝森共富宝通过优质的设备选型、高品质的产品制造、专业的电站设计、可靠的工程质量、完善的售后服务为客户提优质的绿色能源体验。公司从选材、生产、检测、设计、安装、验收、并网、运维等8个环节，通过对137项质量节点的严格管理，实现了电站全生命周期的质量把控。
            </p>
            <p class="foot-main">用清洁能源造福全人类!</p>
            <p class="foot-sub">企业使命</p>
          </div>

          <div v-show="selectIndex == 2" class="describe-main animate__animated animate__fadeIn" style="padding-right: 200px">
            <p class="title">帝森克罗德新能源</p>
            <p class="content" style="padding-top: 30px">
              帝森克罗德新能源公司是济南历城控股集团与帝森克罗德集团合资成立的国有控股公司。<br/>公司致力于户用光伏电站、分布式工商业电站、集中式地面电站以及光储充一体化项目的开发、建设、持有和运营,并在产品方面致力于光伏逆变器、充电桩和储能设备及系统的研发、设计、生产和销售。<br/>公司第一期计划,未来3年内投入资金400亿元,建设、持有电站10吉瓦以上。其中户用分布式光伏电站3吉瓦以上,分布式工商业电站2吉瓦以上,集中式地面电站5吉瓦以上。
            </p>
            <div class="statistics">
              <ul>
                <li>
                  <p><span class="num">400</span><span class="unit">亿元</span></p>
                  <p class="title">3年实现资金投入</p>
                </li>
                <li>
                  <p><span class="num">10</span><span class="unit">吉瓦以上</span></p>
                  <p class="title">建设、持有电站</p>
                </li>
                <li>
                  <p><span class="num">3</span><span class="unit">吉瓦以上</span></p>
                  <p class="title">用户分布式</p>
                </li>
                <li>
                  <p><span class="num">2</span><span class="unit">吉瓦以上</span></p>
                  <p class="title">分布式工商业</p>
                </li>
                <li>
                  <p><span class="num">5</span><span class="unit">吉瓦以上</span></p>
                  <p class="title">集中式地面</p>
                </li>
              </ul>
            </div>
          </div>

          <div v-show="selectIndex == 3" class="describe-main animate__animated animate__fadeIn" style="padding-right: 100px">
            <p class="title">济南历城控股集团</p>
            <p class="content" style="padding-top: 30px">
              济南历城控股集团有限公司是政府财政下属的全资公司。公司成立于2018年8月,注册资金10亿元。<br/>历城控股集团主要承担政府授权范围内的国有资产经营和管理、政府项目投融资管理、对外投融资、土地整理开发、基础设施建设以及配套服务等职能。<br/>历城控股集团将始终坚守“美好生活提供商”的企业定位,力争五年内实现“资产总规模1000亿元、自持产业园区1000万平、人员规模1000人、营业收入100亿元、净利润10亿元”的“五个ー”战略目标,通过综合开发优化城市空间、改善城市品质、塑造城市形态、提升城市能级,引领城市发展,努力成为资产质量高、盈利能力强、品牌优势明显、核心竟争力突出的“国内一流城市综合运营商"。
            </p>
            <div class="statistics">
              <ul>
                <li>
                  <p class="main-title">5年实现</p>
                </li>
                <li>
                  <p><span class="num">1000</span><span class="unit">亿</span></p>
                  <p class="title">资产总规模</p>
                </li>
                <li>
                  <p><span class="num">1000</span><span class="unit">万平</span></p>
                  <p class="title">自持产业园</p>
                </li>
                <li>
                  <p><span class="num">100</span><span class="unit">亿</span></p>
                  <p class="title">营业收入</p>
                </li>
              </ul>
            </div>
          </div>

          <div v-show="selectIndex == 4" class="describe-main animate__animated animate__fadeIn" style="padding-right: 110px">
            <p class="title">帝森克罗德集团</p>
            <p class="content" style="padding-top: 30px">
              帝森克罗德集团公司成立于2007年,是一家全球领先的电力、光电和自动化的技术公司。帝森克罗德致力于为工业、电力、新能源、公共事业的客户提供一流的电力技术、光电技术和自动化技术解决方案,公司通过长期不懈的努力,已成功帮助众多要求近乎完美的用户达成高效、安全、节能、环保的愿望。在近10年的发展历程中,帝森克罗德始终保持业务的高速增长,是中国在电气领域增长最快的公司之一。<br/>
              帝森克罗德公司行政总部位于上海,销售部位于北京,生产和研发中心位于比邻上海的昆山工业园。目前,公司已在国内设立了30个办事处,5个技术支持服务中心,1个商学院培训中心,销售人数达到500人。帝森克罗德公司始终致力于为企业培训一流的人才。同时,公司坚持以极具竞争力的价格,为客户提供科技领先的产品与服务,为客户提供最佳的产品体验,并始终与中国共同成长!
            </p>
            <div class="statistics">
              <ul>
                <li>
                  <p><span class="num">2007</span><span class="unit">年</span></p>
                  <p class="title">公司成立</p>
                </li>
                <li>
                  <p><span class="num">30</span><span class="unit">个</span></p>
                  <p class="title">办事处</p>
                </li>
                <li>
                  <p><span class="num">5</span><span class="unit">个</span></p>
                  <p class="title">技术支持服务中心</p>
                </li>
                <li>
                  <p><span class="num">500</span><span class="unit">人</span></p>
                  <p class="title">销售人数</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
  import Swiper from 'swiper';
  import 'swiper/css/swiper.min.css'
  import foot from "../components/foot";


  export default {
    name: "About",
    components: {foot},
    data(){
      return {
        selectIndex: 1,
        swiper2: null,
        bottom: 0,
      }
    },
    watch: {
      $route(){
        if (this.$route.query.i) {
          this.selectIndex = this.$route.query.i
        }
      }
    },
    mounted() {
      let that = this

      this.$bus.$emit("headerFixed", false)
      this.swiper2 = new Swiper('.mySwiper',{
        pagination:{
          el: '.swiper-pagination',
          bulletElement : 'li',
          clickable: true
        },
        loop:true,
        speed:500,
        observer: true,
        observeParents: true
      })

      this.swiper2.on('slideChange', function () {
        that.selectIndex = Number(this.activeIndex)

        if (that.selectIndex == 5) {
          that.selectIndex = 1
        }
        that.selectMenu(that.selectIndex)
      })

      this.selectIndex = this.$route.query.i ? this.$route.query.i : 1

      this.bottom = (document.querySelector(".foot-content").offsetHeight + 94) + "px"
    },
    methods: {
      selectMenu(index){
        this.selectIndex = index
        this.swiper2.slideTo(index, 1000, false)
      }
    }
  }
</script>

<style lang="less" scoped>

  .about {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }

    img.show {
      height: 100%;
      animation-name: changeBiger;
      animation-duration:50s;
      animation-timing-function:linear;
      animation-fill-mode:forwards;
    }


    .bg {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: 1;
      pointer-events: none;


      > div {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: calc(100vh - 68px);


        .left-layer {

          margin: 6.8% 0px 0px 18.7%;

          img {
            width: 265px;
          }

          .title {
            margin-top: 10px;
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }

          .menu-main {
            margin-top: 40.1%;
            pointer-events: all !important;

            .menu {
              li {
                list-style: none;
                font-size: 17px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin-bottom: 48px;

                a {
                  text-decoration: none;
                  color: #FFFFFF;
                  padding: 5px 0px;

                  &:hover {
                    border-bottom: 2px solid rgba(251, 251, 251, 0.5);
                  }

                  &.active {
                    border-bottom: 2px solid rgba(251, 251, 251, 0.5);
                  }
                }

              }
            }
          }
        }


        .describe {
          position: absolute;
          right: 0px;
          bottom: 6px;
          background: rgba(50, 110, 188, 0.8);
          border-radius: 44px 0px 0px 0px;
          width: 56.1%;
          /*height: 53.6%;*/

          .describe-main {
            padding: 60px 170px 60px 70px;

            .title {
              font-size: 28px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FFFFFF;
            }

            .title-sub {
              font-size: 28px;
              font-family: Source Han Sans CN;
              font-weight: 100;
              color: #FFFFFF;
              margin-top: 30px;
            }

            .content {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 23px;
              letter-spacing: 1px;
            }

            .foot-main {
              font-size: 28px;
              font-family: Source Han Sans CN;
              font-weight: 100;
              color: #FFFFFF;
              margin-top: 11px;
            }

            .foot-sub {
              font-size: 14px;
              color: #FFFFFF;
              font-weight: 300;
            }

            .statistics {
              margin-top: 30px;

              ul {
                display: flex;
                flex-direction: row;

                li {
                  list-style: none;
                  margin-right: 40px;

                  .main-title {
                    height: 100%;
                    font-size: 26px;
                    color: #FFFFFF;
                    font-weight: 100;
                    padding-top: 30px;
                  }

                  p {
                    .num {
                      font-size: 44px;
                      color: #FFFFFF;
                      font-weight: 100;
                      line-height: 54px;
                    }

                    .unit {
                      font-size: 10px;
                      color: #FFFFFF;
                      transform: scale(0.6);
                    }
                  }

                  > .title {
                    font-size: 14px;
                    color: #FFFFFF;
                  }


                }
              }
            }
          }

        }
      }


    }
  }

  @keyframes changeBiger {
    0% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(1);

    }
  }

  .mySwiper /deep/ .swiper-pagination-bullets {
    left: 18.8% !important;
    bottom: 60px !important;
    text-align: left !important;
  }

  .mySwiper /deep/ .swiper-pagination-bullet {
    background: #FFFFFF !important;
    width: 8px;
    height: 8px;
    opacity: 1;
    margin-right: 10px;
  }

  .mySwiper /deep/ .swiper-pagination-bullet-active {
    background: #C20825 !important;
    width: 8px;
    height: 8px;
  }
</style>
